import React, { useState } from 'react';
import Modal from '../Modal';

import { Box, Img, NoButton, Text, Title, WrapButton, YesButton } from './ReturnModal.styled';

import Image from '../../../images/return.png'
import { useReturnOrderMutation } from '../../../redux/orders/ordersApi';
import { showErrorToast, showSuccessToast } from 'utils/showToast';



const ReturnModal = ({ onClose,orderId }) => {
  const [returnOrder] = useReturnOrderMutation();

  const handleReturn = async () => {
  try {
    const data = await returnOrder(orderId)
    console.log('data :>> ', data);
    if (data.error.data.status === "200") {
      showSuccessToast('Кошик повернено')
      onClose();
    } else{
      showErrorToast(data.error.data.message)}
    
  } catch (error) {
    showErrorToast('Помилка повернення')
  }
}


  return (
    <Modal onClose={onClose}>
      <Box>
        <Img src={Image} alt="Вихід" />
        <Title>Повернення кошику</Title>
        <Text>Якщо клієнт повернув кошик, натисніть кнопку «Кошик повернено» для підтвердження.</Text>
        <WrapButton>
          <NoButton onClick={onClose}>Скасувати</NoButton>
          <YesButton
            onClick={handleReturn}>Кошик повернено</YesButton>

        </WrapButton>
      </Box>

    </Modal>
  );
};

export default ReturnModal;
