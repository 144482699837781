import { useEffect, useState } from 'react';
import {
  BagsTitleBox,
  BagsBox,
  BagsBodyBox,
  BagsTitle,
  FilterBox,
  InfoBox,
  InfoText,
  Table,
  TableHead,
  TableHeader,
  TableBody,
  TableRow,
  TableData,
  PrevIcon,
  PaginationContainer,
  PageNumbersContainer,
  NextIcon,
  PaginationButton,
  SearchInput, // Додаємо стиль для поля пошуку
  SearchLabel,
  IconSearch,
  StatusWrap,
  IconReturn
} from './OrdersToolbar.styled';

import { Label1 } from '../ArchiveBags/ArchiveBags.styled';
import CustomSelect from 'components/other/CustomSelect';
import ReturnModal from 'components/modal/ReturnModal';
import { useNavigate } from 'react-router-dom';

export const FormatNumber = ( number ) => {
  // Форматуємо число у потрібний формат
  const formatNumber = (num) => {
    if (!num) {
      return ''; // Повертаємо повідомлення, якщо число некоректне
    }
    const str = num.toString(); // Додаємо нулі, якщо їх не вистачає
    return `${str.slice(0, 4)}-${str.slice(4, 9)}-${str.slice(9, 14)}-${str.slice(14)}`; // Додаємо останній блок
  };

  // Виділяємо останні 4 цифри
  const boldLastFour = (formattedNum) => {
    const plainString = formattedNum.replace(/-/g, ''); // Видаляємо дефіси
    const lastFour = plainString.slice(-4); // Беремо останні 4 символи
    const withoutLastFour = formattedNum.slice(0, -5); // Беремо все, крім останніх 5 (враховуючи дефіс)
    return (
      <>
        {withoutLastFour}
        <strong>{lastFour}</strong>
      </>
    );
  };

  const formattedNumber = formatNumber(number);

  return <p>{boldLastFour(formattedNumber)}</p>;
};




const OrdersToolbar = ({ ordersData, orderStatuses, currentPage, setCurrentPage, searchKeyword, setSearchKeyword }) => {

  // Додаємо стан для пошуку
 const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [selectedValue, setSelectedValue] = useState("Всі статуси");
  const [statuses, setStatuses] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const handleOpenModal = () => {
    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    document.body.style.overflow = 'auto';
  
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);
  useEffect(() => {
    if (ordersData) {
      console.log('ordersData :>> ', ordersData);
      setOrders([...ordersData.data]);
    }
  }, [ordersData]);

  useEffect(() => {
    if (orderStatuses) {
      const inStatus = Object.values(orderStatuses) || [];
      setStatuses(inStatus);
    }
  }, [orderStatuses]);

  const handleSelectChange = (event) => {
    setSelectedValue(event);
  };

  const handleSearchChange = (event) => {
    setSearchKeyword(event.target.value);
    setCurrentPage(1); // Скидаємо на першу сторінку при новому пошуку
  };

  const filterOrders = () => {
    return orders.filter(order => {
      const isStatusMatch = selectedValue === "Всі статуси" || selectedValue === order.statusName;
      return isStatusMatch;
    });
  };

  const filteredOrders = filterOrders();
  const totalPages = ordersData?.pagination?.pageCount || 1;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const statusColors = {
    hold: "var(--colors-primary-600)",        // Жовтий для "Викуплено"
    created: "var(--colors-warning-400)",     // Синій для "Заброньовано"
    waiting: "var(--colors-warning-400)",     // Помаранчевий для "Очікує на отримувача"
    cancelled: "var(--colors-grey-400)",   // Червоний для "Скасовано"
    unsuccessful: "var(--colors-error-400)", // Сірий для "Час вичерпано"
    done: "var(--colors-secondary-600)",
    ended: "var(--colors-error-400)",
    reverted: "var(--colors-grey-400);",// Зелений для "Товар отримано"
  };

  return (
    <BagsBox>
      <BagsTitleBox>
        <BagsTitle>Бронювання</BagsTitle>
        <FilterBox>
          <SearchLabel>
            <IconSearch />
            <SearchInput
              type="text"
              value={searchKeyword}
              onChange={handleSearchChange}
              placeholder="Пошук по назві або ID"
            />
          </SearchLabel>
          <Label1 htmlFor="pickupTheme">
            <CustomSelect
              width={"336px"}
              options={["Всі статуси", ...statuses]}
              selectedValue={selectedValue}
              onChange={handleSelectChange}
            />
          </Label1>
        </FilterBox>
      </BagsTitleBox>

      <BagsBodyBox>
        {filteredOrders.length === 0 ? !selectedValue ? (
          <InfoBox>
            <InfoText>У вас поки немає зарахувань.</InfoText>
            <InfoText>Вони з’являться тут після того, як хтось забронює ваш диво-кошик через додаток Strava Poruch та забере його в точці видачі.</InfoText>
          </InfoBox>
        ) : (
          <InfoBox>
            <InfoText>Бронювань не знайдено</InfoText>
          </InfoBox>
        ) : (
          <Table>
            <TableHead>
              <tr>
                <TableHeader key="name">Назва диво-кошику</TableHeader>
                <TableHeader key="count">Кількість</TableHeader>
                <TableHeader key="sum">Вартість</TableHeader>
                <TableHeader key="number">Статус</TableHeader>
                  <TableHeader key="date">ID</TableHeader>
                  <TableHeader key="key"></TableHeader>
              </tr>
            </TableHead>
            <TableBody>
              {filteredOrders.map(order => (
                <TableRow key={order.id}>
                  <TableData onClick={() => { navigate(`/main/viewbag/${order.bag.id}`) }} >{order.bag.name}</TableData>
                  <TableData onClick={() => { navigate(`/main/viewbag/${order.bag.id}`)}} >{order.count} шт.</TableData>
                  <TableData onClick={() => { navigate(`/main/viewbag/${order.bag.id}`) }} >{order.amount} грн</TableData>
                  <TableData onClick={() => { navigate(`/main/viewbag/${order.bag.id}`) }} ><StatusWrap color={statusColors[order.status]} >{order.statusName}</StatusWrap></TableData>
                  <TableData onClick={() => { navigate(`/main/viewbag/${order.bag.id}`) }} >{FormatNumber(order?.key)}</TableData>
                  <TableData>{order.return && <IconReturn onClick={(e) => { e.stopPropagation(); setOrderId(order.id); handleOpenModal()}}/>}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {
          isModalOpen  && (
            <ReturnModal onClose={handleCloseModal} orderId={orderId} />
          )
        }

      </BagsBodyBox>

      

      {totalPages > 1 && (
        <PaginationContainer>
          <PrevIcon onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
          <PageNumbersContainer>
            {Array.from({ length: totalPages }, (_, index) => (
              <PaginationButton
                key={`page-${index + 1}`}
                onClick={() => handlePageChange(index + 1)}
                disabled={index + 1 === currentPage}
              >
                {index + 1}
              </PaginationButton>
            ))}
          </PageNumbersContainer>
          <NextIcon onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
        </PaginationContainer>
      )}
    </BagsBox>
  );
};

export default OrdersToolbar;
