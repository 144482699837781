import React from 'react';


import { Box, Img, NoButton, Text, Title, WrapButton, YesButton } from './OnboardBody.styled';

import Image from '../../../../images/onBoard.png'
import Image1 from '../../../../images/FullAcses.png'
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { showSuccessToast } from 'utils/showToast';

const OnboardBody = ({ onClose, type, onOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Box>
      <Img src={type === 'start' ? Image : Image1} alt="Довідка" />
      <Title>{type === 'start' ? 'Ласкаво просимо до Вашого кабінету STRAVA PORUCH' : 'Доступ до повного функціоналу'}</Title>
      <Text>{type === 'start' ? 'Ми підготували для Вас невеликий ознайомчий тур по нашому сервісу' : 'Щоб мати можливість продавати свої диво-кошики – сплатіть річний внесок. Сформуйте рахунок самостійно або замовте дзвінок і наш менеджер відповість на Ваші запитання та надішле рахунок на пошту.'}</Text>
      <WrapButton className={type}>
        <YesButton type={type} onClick={() => { onClose(); if(type !== 'start')  showSuccessToast('Запит прийнято наш менеджер передзвонить Вам'); }}>{type === 'start' ? 'Пропустити' : 'Замовити дзвінок'}</YesButton>
        <NoButton type={type}
          onClick={() => {
            if (type === 'start') {
              navigate('/main?onboard=1')
              onClose();
              document.body.style.overflow = 'auto';
            } else {
              onClose();
              onOpen();
            }
          }}>{type === 'start' ? 'Розпочати' : 'Сформувати рахунок'}</NoButton>

      </WrapButton>
    </Box>
  );
};

export default OnboardBody;