import styled from '@emotion/styled';

import { ReactComponent as EyeIcon } from '../../../images/eye.svg';
import { ReactComponent as DownloadIcon } from '../../../images/download.svg';

export const BagsBox = styled.div`
 
border-radius: 16px;
padding: 16px;
padding-bottom: 0;

width: 100%;
height: fit-content;
background: var(--colors-grey-50);
  /* @media screen and (min-width: 375px) {
    width: 375px;
    margin: 0 auto;
  }

  @media screen and (min-width: 768px) {
    width: 768px;
  }

  @media screen and (min-width: 1440px) {
    width: 1440px;
    display: flex;
    max-height: 100vh;
  } */
`;


export const BagsTitleBox = styled.div`
  display: flex;
align-items: flex-start;
justify-content: space-between;
border-bottom: 1px solid var(--colors-grey-200);
padding: 0px 0px 12px 0px;
width:100%;


`;

export const BagsBodyBox = styled.div`
width: 100%;
display: flex;
gap:16px;
padding: 16px 0 16px 0;


`;


export const BagsTitle = styled.h2`
display: flex;
align-items: center;
gap:8px;
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
color: var(--colors-grey-800);
`;


export const DownloadBox = styled.div`
width: calc((100% - 16px) / 2);
display: flex;
align-items: center;
justify-content: space-between;
background: var(--colors-grey-25);
border-radius: 16px;
padding: 16px;
height: 56px;
`;

export const DownloadButtonBox = styled.div`
display: flex;
align-items: center;
gap:16px;

`;

export const DownloadName = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
color: var(--colors-grey-900);
`;

export const IconEye = styled(EyeIcon)`
  width: 24px;
  height: 24px;
  stroke: var(--colors-grey-400);
  cursor: pointer;

`;
export const IconDownload = styled(DownloadIcon)`
 width: 24px;
  height: 24px;
  cursor: pointer;
`;