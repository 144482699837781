// // MyLottieAnimation.js
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../lotties/logo.json'; // замініть на шлях до вашого файлу анімації

const MyLottieAnimation = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ backgroundColor: '#7F56D9', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Lottie options={defaultOptions} height={1400} width={900} />
    </div>
  );
};

export default MyLottieAnimation;
