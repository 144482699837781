import styled from '@emotion/styled';
export const ButtonWrap = styled.div`

display: flex;
align-items: center;
justify-content: space-between;
gap:12px;
width:100%;


`;
export const ModalTitle = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
width:100%;
`;



export const CancelButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
width:calc((100% - 12px) / 2);

display: flex;
align-items: center;
color: var(--colors-primary-600);
background:none;
border: 1px solid var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;
justify-content: center;
`;

export const SaveButton = styled.button`
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-25);
display: flex;
justify-content: center;
width:calc((100% - 12px) / 2);
align-items: center;
background: var(--colors-primary-600);
border-radius: 360px;
padding: 8px 12px;

`;



export const Box = styled.div`


  width: 386px;


background: none;
appearance: none;



`;



export const Img = styled.img`

object-fit: contain;
border-radius: 40px;
margin-top: 10px;
width: 386px;
height: 184px;
margin-bottom: 16px;
`;
export const Title = styled.h2`
font-family: var(--font-family);
font-weight: 600;
font-size: 20px;
line-height: 135%;
text-align: center;
color: var(--colors-grey-900);
margin-bottom: 4px;
`;

export const Text = styled.p`
font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-grey-600);
margin-bottom: 24px;
`;
export const WrapButton = styled.div`
display: flex;
flex-direction: column-reverse;
gap: 12px;
width: 100%;

`;

export const YesButton = styled.button`
cursor: pointer;

border-radius: 360px;
padding: 8px 12px;
width: 100%;
height: 48px;
display: flex;
align-items: center;
justify-content: center;
font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
background: var(--colors-primary-600);
color: var(--colors-grey-25);

`;

export const NoButton = styled.button`
cursor: pointer;
border-radius: 360px;
padding: 8px 12px;
width: 100%;
height: 48px;
display: flex;
align-items: center;
justify-content: center;

font-family: var(--font-family);
font-weight: 600;
font-size: 16px;
line-height: 137%;
text-align: center;
color: var(--colors-primary-600);
background: none;
border: 1px solid var(--colors-primary-600);
`;

