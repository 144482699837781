import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';

import {
  Container,

  PageHeader,
  NotificBtn,
  ButtonBack,
  BackIcon,
  NotificWrap,
} from './Header.styled';
import { useGetBagsQuery, useGetMonthlyTasksQuery } from '../../../redux/bags/bagsApi';
import { useGetNotificationsQuery } from '../../../redux/notification/notificationApi';
import NotificationModal from 'components/modal/NotificationModal/NotificationModal';

import { io } from 'socket.io-client';
import { useSelector } from 'react-redux';
import { selectToken } from '../../../redux/auth/selectors';
import OrdersConfirm from 'components/modal/OrdersConfirm/OrdersConfirm';
import ReturnModal from 'components/modal/ReturnModal';
const notifications = [
  {
    id: 245,
    title: "Кошик заброньовано",
    message: "Ваш кошик успішно заброньовано. Очікуйте на підтвердження.",
    createdAt: new Date('2024-09-26T10:00:00Z'), // ISO формат дати
    read: false,
  },
  {
    id: 246,
    title: "Кошик забрано",
    message: "Ваш кошик був успішно забрано. Дякуємо за покупку!",
    createdAt: new Date('2024-09-26T11:00:00Z'),
    read: true,
  },
  {
    id: 243,
    title: "Нове сповіщення",
    message: "Ви отримали нову пропозицію на ваш кошик.",
    createdAt: new Date('2024-09-26T12:00:00Z'),
    read: false,
  },
  {
    id: 236,
    title: "Кошик заброньовано",
    message: "Ваш кошик успішно заброньовано. Спішіть завершити покупку!",
    createdAt: new Date('2024-09-26T13:00:00Z'),
    read: false,
  },
  {
    id: 328,
    title: "Кошик забрано",
    message: "Ваш кошик був успішно забрано. Сподіваємося на нові покупки!",
    createdAt: new Date('2024-09-26T14:00:00Z'),
    read: true,
  },
];



const Header = ({ openSidebar, isLightTheme, setIsLightTheme }) => {
  const { data: NotiData, isLoading: isLoadingNoti } = useGetNotificationsQuery();
  const modalRef = useRef(null);

  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const token = useSelector(selectToken);
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpenRet, setModalOpenRet] = useState(false);
  const [selectOrderId, setSelectOrderId] = useState(false);
  const [isModalOpenOrder, setModalOpenOrder] = useState(false);
  const [visibleBack, setVisibleBack] = useState(false);
  const [notificationsAr, setNotificationsAr] = useState([]);
  const [markUnRead, setMarkUnRead] = useState(false);
  const [searchParams] = useSearchParams();
  const currentPath = location.pathname;
  const lastPage = currentPath.split('/').length - 1 >= 3 ? currentPath.split('/')[currentPath.split('/').length - 2] : currentPath.split('/')[currentPath.split('/').length - 1];
  console.log('lastPage :>> ', lastPage);
  /* eslint-disable */
  const [_, currentPage, __, currentDate] = currentPath.split('/');
  /* eslint-enable */

  const handleMessage = (data) => {
    console.log('Message received:', data);
  };

  const handleError = (error) => {
    console.error('WebSocket error:', error);
  };



  const handleBackClick = () => {
    // Повернення до попередньої сторінки
    navigate(-1);
  };
  useEffect(() => {
    const baseUrl = "https://test-backend.stravaporuch.com.ua:443";
    const newSocket = io.connect(baseUrl, {
      path: "/ws",
      query: {
        auth_token: token,
      },
      secure: true
    });

    newSocket.on('connect', () => {
      console.log('Socket підключено');
    });

    newSocket.on('message', (data) => {

      console.log('повідомлення :>> ', data);
      const parsedCreatedAt = new Date(data.createdAt); // Передаємо оригінальний рядок без змін
      const newNotification = {
        id: data.id,
        title: data.title,
        message: data.message,
        createdAt: isNaN(parsedCreatedAt) ? new Date() : parsedCreatedAt, // Перевіряємо, чи дата валідна
        read: !data.unread
      };

      // Додаємо нове повідомлення в початок масиву сповіщень
      setNotificationsAr(prevNotifications => [newNotification, ...prevNotifications]);
      if (data.type === 'request-receipt') {
        setSelectOrderId(data.object_id)
        handleOpenModalOrder();
   }
      if (data.type === 'request-return') {
        setSelectOrderId(data.object_id)
        handleOpenModalOrder();
      }
   
    });

    newSocket.on('error', (err) => {
      console.error('Помилка Socket:', err);
    });

    newSocket.on('disconnect', () => {
      console.log('WebSocket відключено');
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);




  useEffect(() => {

    switch (currentPage) {


      case 'main':
        return setActivePage("Головна");

      case 'statistics':
        return setActivePage("Статистика");

      case 'financial':
        return setActivePage("Фінанси");

      case 'services':
        return setActivePage("Служба підтримки");

      case 'settings':
        return setActivePage("Налаштування");

      default:
        setActivePage('');
        return;
    }
  }, [currentPage]);

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };

  const handleOpenModalRet = () => {
    setModalOpenRet(!isModalOpenRet);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModalRet = () => {
    setModalOpenRet(false);

    document.body.style.overflow = 'auto';

  };


  const handleOpenModalOrder = () => {
    setModalOpenOrder(!isModalOpenOrder);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModalOrder = () => {
    setModalOpenOrder(false);

    document.body.style.overflow = 'auto';

  };


  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
    const handleClickOutside = (event) => {
      if (isModalOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    }
    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpen]);





  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
    const handleClickOutside = (event) => {
      if (isModalOpenOrder ) {
        handleCloseModal();
      }
    }
    if (isModalOpenOrder) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpenOrder]);
  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModalRet();
      }
    };
    const handleClickOutside = (event) => {
      if (isModalOpenRet) {
        handleCloseModalRet();
      }
    }
    if (isModalOpenRet) {
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('click', handleClickOutside);
    };
  }, [isModalOpenRet]);



  useEffect(() => {
    const categoryId = searchParams.get('category');
    if (categoryId || lastPage === "archivebags") {
      setVisibleBack(true)
    } else setVisibleBack(false)
    if (lastPage === "edit" || lastPage === "addbag" || lastPage === "viewbag" || lastPage === "addshop") setVisibleBack(true);
  }, [searchParams, lastPage]);


  useEffect(() => {

    if (NotiData) {

      setNotificationsAr([...NotiData.data])

    }

  }, [NotiData]);



  useEffect(() => {



    setMarkUnRead(notificationsAr.filter(item => !item.read).length > 0 ? true : false)

  }, [notificationsAr]);



  return (
    <Container>
      {visibleBack ? <ButtonBack onClick={handleBackClick}><BackIcon />Назад</ButtonBack> :
        <PageHeader >{activePage}</PageHeader>}

      <NotificWrap onClick={handleOpenModal} hide={markUnRead ? 'true' : 'false'} >
        <NotificBtn />
        {isModalOpen && !isLoadingNoti && (
          <NotificationModal ref={modalRef} onClose={handleCloseModal} notificationsAr={notificationsAr} setNotificationsAr={setNotificationsAr} markUnRead={markUnRead} setSelectOrderId={setSelectOrderId} handleOpenModalOrder={handleOpenModalOrder} handleOpenModalOrderRet={handleOpenModalRet} />
        )}
        {isModalOpenOrder &&  (
          <OrdersConfirm onClose={handleCloseModalOrder} orderId={selectOrderId} />
        )}

        {
          isModalOpenRet && (
            <ReturnModal onClose={handleCloseModalRet} orderId={selectOrderId} />
          )
        }


      </NotificWrap>




    </Container>
  );
};

export default Header;
