import React, { useEffect, useState } from 'react';
import Modal from '../Modal';


import { ButtonWrap, CancelButton, DetailModalAddres, DetailModalAddress, DetailModalBox, DetailModalId, DetailModalInput, DetailModalItem, DetailModalLabel, DetailModalSpan, ModalTitle, SaveButton } from './OrdersConfirm.styled';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/auth/operations';
import { useConfirmOrderMutation, useGetOrderQuery, useGetOrdersQuery } from '../../../redux/orders/ordersApi';
import { FormatNumber } from 'components/main/OrdersToolbar/OrdersToolbar';
import { showErrorToast, showSuccessToast } from 'utils/showToast';
import ImageAnimation1 from 'components/layout/loader/ImageAnimation1';
import ImageAnimation2 from 'components/layout/loader/ImageAnimation2';
import ImageAnimation from 'components/layout/loader/ImageAnimation';
import ImageAnimation3 from 'components/layout/loader/ImageAnimation3';

const OrdersConfirm = ({ onClose, orderId }) => {

  const { data: order, isLoading: isLoadingOrder } = useGetOrderQuery(orderId);
  const [confirmOrder, ] = useConfirmOrderMutation();


useEffect(()=>{if(order)console.log('order :>> ', order);},[order])


  const handleSave = async () => {

   
    try {
     const data= await confirmOrder(orderId)
        
      if (data.success) {
        showSuccessToast('Кошик видано')
        onClose();
      } else {
        console.log('dataewqdrwq :>> ', data);
        showErrorToast(data.error.data.message)
        data.error.data.details.map(item => showErrorToast(item))
        
}
        
      console.log('Details  successfully');
   


    } catch (error) {
      console.error('Failed to Details:', error);
      console.log('error :>> ', error);
      showErrorToast("Помилка cthdthe")
    }
    onClose();
  };


  return (

    <Modal onClose={onClose} loading={isLoadingOrder?0:1}>

      <ModalTitle>Видати замовлення</ModalTitle>
          {order && <DetailModalBox>
            <DetailModalAddress>{`${order?.shop.name} (${order?.shop.address})`}</DetailModalAddress>
            <DetailModalId>{FormatNumber(order?.orderId) }</DetailModalId>
            <DetailModalItem>
              <DetailModalLabel>Диво-кошик</DetailModalLabel>
              <DetailModalSpan>{order?.count} шт</DetailModalSpan>
            </DetailModalItem>
          </DetailModalBox>}
      <ButtonWrap>
       <CancelButton onClick={onClose}>Скасувати</CancelButton>
        <SaveButton onClick={handleSave} >Видано отримувачу</SaveButton> 
          </ButtonWrap>
 
    </Modal>
     
  );
};

export default OrdersConfirm;