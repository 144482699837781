import { useState, useRef, useEffect } from 'react';
import { SelectBox, SelectedValue, OptionList, OptionItem, IconArrowDown, IconCheck, Values } from './CustomSelect.styled';

const CustomSelect = ({ options, selectedValue, onChange, width = "240px" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (value) => {
    onChange(value);
    setIsOpen(false);
  };
  const isSelectedInvalid = !options.includes(selectedValue);

  // Закриває селект, якщо клік відбувся поза межами компонента
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SelectBox ref={selectRef}>
      <SelectedValue width={width} className={isOpen ? "openselect" : ""} onClick={toggleOpen}
        color={
          isSelectedInvalid ? 'true' : '' // Зміна кольору, якщо selectedValue некоректний або перший елемент
        }  >
        <Values>{selectedValue}</Values>
        <IconArrowDown isopen={isOpen ? "true" : ""} />
      </SelectedValue>
      {isOpen && (
        <OptionList>
          {options.map((option, index) => (
            <OptionItem key={index + 1} onClick={() => handleOptionClick(option)}>
              {option} {selectedValue === option ? <IconCheck /> : null}
            </OptionItem>
          ))}
        </OptionList>
      )}
    </SelectBox>
  );
};

export default CustomSelect;
