import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import 'react-quill/dist/quill.snow.css';


import { ButtonWrap, CancelButton, DetailModalBox, DetailModalInput, DetailModalItem, DetailModalLabel, ModalTitle, SaveButton } from './DocsViewModal.styled';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/auth/operations';
import ModalViewDoc from '../ModalViewDoc';
import { useNavigate } from 'react-router-dom';
import { IconDownload } from 'components/finance/FinanceDocument/FinanceDocument.styled';


const DocsViewModal = ({ onClose, item, handleDownloadPdf }) => {
  console.log('item :>> ', item);

  const navigate = useNavigate();






  return (
    <ModalViewDoc onClose={onClose}>
      <ModalTitle>{item.typeName}</ModalTitle>
      <DetailModalBox>
        <DetailModalInput className='ql-editor' dangerouslySetInnerHTML={{ __html: item.text }} />


      </DetailModalBox>





      <ButtonWrap>
        <CancelButton onClick={onClose}>Закрити</CancelButton>
     

      <SaveButton onClick={() => { handleDownloadPdf(item) }} >Завантажити</SaveButton>
      </ButtonWrap>
    </ModalViewDoc>
  );
};

export default DocsViewModal;