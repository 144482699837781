import React, { useEffect, useState } from 'react';
import Modal from '../Modal';


import { ButtonWrap, CancelButton, CheckBoxWrapper, DetailModalBox, DetailModalInput, DetailModalItem, DetailModalLabel, Label1, LinksContainer, LinkTo, ModalAmountBox, ModalAmountText, ModalAmountValue, ModalInfoText, ModalTitle, SaveButton } from './MerchantBill.styled.js';
import { useDispatch } from 'react-redux';
import { getMerchantBill, updateUser } from '../../../redux/auth/operations';

const MerchantBill = ({ onClose, merchant }) => {
  const dispatch = useDispatch();


  const [organizationName, setOrganizationName] = useState(merchant?.organizationName ? merchant?.organizationName : '');
  const [organizationCode, setOrganizationCode] = useState(merchant?.organizationCode ? merchant?.organizationCode : '');
  const [liqpay, setLiqpay] = useState(merchant?.portmone_id  ? merchant?.portmone_id : '');
  const [isDisabled, setIsDisabled,] = useState(organizationName && organizationCode && liqpay ? '' : 'true');
  const [isChecked, setIsChecked] = useState(false);
  useEffect(() => {

    if (organizationName && organizationCode && liqpay && isChecked === isDisabled)
      return
    else
      setIsDisabled(organizationName && organizationCode && liqpay && isChecked ? '' : 'true')
  },
    [organizationName, organizationCode, liqpay, isDisabled, isChecked])


  const handleSave = async () => {

    if (isDisabled) return; 

    try {

      if (!merchant.organizationName || !merchant.organizationCode || !merchant.portmone_id) {
        await dispatch(updateUser({ organizationName: organizationName, organizationCode: organizationCode, portmone_id: liqpay }));
        console.log('Details  successfully');
      }

      const downloadData = await dispatch(getMerchantBill());
      console.log('downloadData :>> ', downloadData);
      if (downloadData) {
        // Припустимо, downloadData є бінарними даними, які потрібно завантажити
        const url = window.URL.createObjectURL(new Blob([downloadData], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.download = `document_${organizationCode}.pdf`; // Ім'я файлу для завантаження
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

      }
    } catch (error) {
      console.error('Failed to Details:', error);
    }
    onClose();
  };
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  }

  return (
    <Modal onClose={onClose}>
      <ModalTitle>Додайте реквізити</ModalTitle>
      <ModalInfoText>Щоб сформувати рахунок, заповніть поля нижче. Будьте уважні, адже у випадку невірно введених даних, рахунок може бути недійсним.</ModalInfoText>
      <ModalAmountBox>
        <ModalAmountText>
          Ціна річного внеску
        </ModalAmountText>
        <ModalAmountValue>
          {1000} грн.
        </ModalAmountValue>
      </ModalAmountBox>
      <DetailModalBox>
        <DetailModalItem>
          <DetailModalLabel htmlFor='organizationName'>Назва мерчанта (юр. особи або ФОП)</DetailModalLabel>
          <DetailModalInput id="organizationName" name='organizationName' placeholder='Введіть назву мерчанта' value={organizationName} onChange={(e) => { setOrganizationName(e.target.value) }} />
        </DetailModalItem>
        <DetailModalItem>
          <DetailModalLabel htmlFor='organizationCode'>Код ЄДРПОУ (для юр. осіб) або РНОКПП (для ФОП)</DetailModalLabel>
          <DetailModalInput id="organizationCode" name='organizationCode' placeholder='Введіть код' value={organizationCode} onChange={(e) => { setOrganizationCode(e.target.value) }} />
        </DetailModalItem>
        <DetailModalItem>
          <DetailModalLabel htmlFor='liqpay'>Portmone ID</DetailModalLabel>
          <DetailModalInput id="liqpay" name='liqpay' placeholder='Введіть LiqPay ID' value={liqpay} onChange={(e) => { setLiqpay(e.target.value) }} />
        </DetailModalItem>
      </DetailModalBox>
      <LinksContainer style={{ justifyContent: 'flex-start' }}>
        <CheckBoxWrapper>
          <input type="checkbox" id="confirm" checked={isChecked}
            onChange={handleCheckboxChange} />
          <label htmlFor="confirm" className="checkmark"></label>
        </CheckBoxWrapper>
        <Label1 htmlFor="confirm">Я згоден (-на) з <LinkTo style={{ fontSize: '15px', marginTop: '0px', textDecoration: 'underline' }}>Публічною офертою</LinkTo></Label1>

      </LinksContainer>




      <ButtonWrap>
        <CancelButton onClick={onClose}>Скасувати</CancelButton>
        <SaveButton onClick={handleSave} disabled={isDisabled ? true : false}>Сформувати рахунок</SaveButton> :

      </ButtonWrap>
    </Modal>
  );
};

export default MerchantBill;