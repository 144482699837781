import styled from '@emotion/styled';
import { ReactComponent as ArrowDown } from '../../../images/arrow/down.svg';
import { ReactComponent as CheckIcon } from '../../../images/check.svg';
export const SelectBox = styled.div`
  position: relative;
 width: 100%;

  cursor: pointer;
`;

export const SelectedValue = styled.div`
 width: ${({ width }) => width};
  height: 48px;
   padding-right: 38px; /* Відступ для розміщення стрілки справа */
  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
 white-space: nowrap;      /* Текст не переноситься на новий рядок */
  overflow: hidden;         /* Все, що виходить за межі елемента, буде приховано */
  text-overflow: ellipsis;
  color:${({ color }) => color ? 'var(--colors-grey-400)' : 'var(--colors-grey-800)'};
  border: 1px solid var(--colors-grey-100);
border-radius: 360px;
padding: 8px 16px;
background: var(--colors-grey-25);
outline: none;
display: flex;
align-items: center;

&.openselect{
  border-radius:24px;
   border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
`;

export const Values = styled.p`
 width: 96%;
  height: fit-content;

  font-family: var(--font-family);
font-weight: 500;
font-size: 16px;
line-height: 137%;
 white-space: nowrap;      /* Текст не переноситься на новий рядок */
  overflow: hidden;         /* Все, що виходить за межі елемента, буде приховано */
  text-overflow: ellipsis;
  color:currentColor;

`;


export const OptionList = styled.ul`
 box-shadow: 0 2px 8px 0 rgba(152, 152, 179, 0.16);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
 border-bottom: 1px solid var(--colors-grey-100);
border-left: 1px solid var(--colors-grey-100);
border-right: 1px solid var(--colors-grey-100);
border-radius: 0 0 24px 24px;
padding: 12px 16px;
  z-index: 1000;
background: var(--colors-grey-25);
width: 100%;
`;

export const OptionItem = styled.li`
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colors-grey-100);
  padding: 8px 0px 8px 0px;
  cursor: pointer;
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 16px;
  line-height: 137%;
  color: var(--colors-grey-900);
  
  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
    border: none;
  }
`;

export const IconArrowDown = styled(ArrowDown)`
  width: 14px;
  height: 8px;
     content: '';
    position: absolute;
    top: 42%;
    right: 15px;



    pointer-events: none;
   transform: ${({ isopen }) => (isopen ? 'rotate(-180deg)' : 'rotate(0deg)')};
   transition: transform 0.3s ease-in-out;
`;
export const IconCheck = styled(CheckIcon)`
  width: 24px;
  height: 24px;

`;