import { useGetDocumentQuery } from "../../../redux/documents/documentsApi";
import { List, ListItem, StyledLink } from "./LegalSection.styled";
import { useEffect, useState } from "react";
import DocsViewModal from "components/modal/DocsViewModal/DocsViewModal";
import convertHtmlToPdf from "utils/function";







const LegalSection = () => {

  const { data: viewData, isSuccess: viewSuccess } = useGetDocumentQuery();
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectItem, setSelectItem] = useState({});

  const handleOpenModal = (filter, filter2) => {
    const filteredData = viewData.filter(
      item => item.type === filter && item.appointment === filter2
    );
    setSelectItem(filteredData[0])


    setModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseModal = () => {
    setModalOpen(false);

    document.body.style.overflow = 'auto';

  };


  // Обробка завантаження документа
  const handleDownloadPdf = (filter, filter2) => {
    const filteredData = viewData.filter(
      item => item.type === filter && item.appointment === filter2
    );
    convertHtmlToPdf(filteredData[0]);
  };



  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };

    if (isModalOpen) {
      window.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isModalOpen]);
  return (

    <List>
      <ListItem>
        <StyledLink onClick={() => { handleOpenModal('privacyPolicy', 'all') }}>Політика конфіденційності</StyledLink>
      </ListItem>
      <ListItem>
        <StyledLink onClick={() => { handleOpenModal('agreement', 'merchant') }}>Умови користування</StyledLink>
      </ListItem>
      {isModalOpen && (
        <DocsViewModal onClose={handleCloseModal} item={selectItem} handleDownloadPdf={handleDownloadPdf} />
      )}
    </List>

  );
};

export default LegalSection;